<template>
 <div class="boxpa">
    <div class="flexcent title"> 反馈与建议 </div>
    <div v-if="done" class="done"> 
      <p>感谢您提供的反馈建议，我们会尽快处理！</p>
      
    </div>
    <div class="frombox" v-if="!done">
      <el-form :model="form" :rules="formRules" ref="regForm"  >
        <!-- <el-form-item label="标题" prop="title">
           <el-input type="text" v-model="form.title" placeholder="请输入标题" maxlength="80" rows="1" ></el-input>
        </el-form-item>  -->
        <el-form-item label="反馈建议" prop="content">
           <el-input type="textarea" v-model="form.content" placeholder="我有话说..." maxlength="500" rows="3"/>
        </el-form-item> 
      </el-form>
      <div class="flexcent">
        <!-- <div class="flexcent regbtn"  @click="resetForm">放弃</div> -->
        <div class="flexcent regbtn"  @click="addSuggestion">{{submitting ? "正在提交" : "确定" }}</div>
      </div>
    </div>
   
  </div>
</template>

<script>
// import htmlFrame from "@/components/all/htmlFrame"; //引入尾部组件

export default {
  name: "suggest",  
  components: {
    // htmlFrame
  },
  data(){
    return {
      done: false,
      submitting: false,
      userInfo: null,
      form: {
        title: "", //用户名
        content: "",
        username: null,
        email: null,
        mobile: null,
      },
      formRules: {
      
        // title: [
        //   { required: true, message: "请输入标题，长度3-80个字!", trigger: "blur" },
        //   {
        //     min: 3,
        //     max: 80, 
        //     message: "请输入标题，长度3-80个字",
        //     trigger: "blur",
        //   },
        //  ],
         content: [
          { required: true, message: "请输描述，长度10-500个字", trigger: "blur" },
          {
            min: 10,
            max: 500, 
            message: "请输描述，长度10-500个字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
      let checkLogin = this.until.checkLogin();
    if(!checkLogin) {
      let path = this.$route.path
      this.until.jump.call(this,'/tologin', "push", {title:"反馈与建议", redirect:path});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.uid = this.until.getUserInfo().id
      this.readonly = !this.editable
      this.getUserInfo()
    }
  },
  mounted() {
    // this.getlist();
  },
  methods: {
    resetForm(){
      this.submitting = false
      this.form.title = null  //用户名
      this.form.content = null
      // Object.assign(this.form, this.userInfo );
    },
    addSuggestion(){
      console.log("add suggestion ")
      this.$refs["regForm"].validate((valid) => {
          console.log(" valid ", valid)
          if (valid) {
            this.doAdd()
          } else {
            console.log('error submit!!');
            return false;
          }
      });
    },
    doAdd(){
      this.submitting = true
      this.$api.addSuggestion(this.form).then((res) => {
        this.submitting=false
        if (res.code == 0) {
          this.done = true
          this.$message.success("感谢您提供反馈建议");
        } else {
           this.$message.error(res.message);
        }
      }).catch((err)=>{
        this.done = true
        this.submitting=false
        console.info(" add error ", err)
        // this.$message.error("提交建议失败，请重新提交");
         this.$message.success("感谢您提供反馈建议");
      });

    },
     getUserInfo() {
      let username = this.until.getUserInfo().username;
      console.log(" username :" , username   )
      this.$api.getUserInfo(username).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data.sysUser;
          Object.assign(this.form, this.userInfo);
          this.form.email2 = this.userInfo.email
          this.form.mobile2 = this.userInfo.mobile
        }

      }).catch((err)=>{
        this.submitting=false
        console.error(err)
      });
    },
  },
};
</script>

<style lang="less"  scoped>
// @media screen and (min-width: 1000px) {
.boxpa {
  // background-color: #f9f9f9;
  // width: 800px ;
  height: 100vh;
  border: #3a8ee6 1px;
  // 首页
  .title {
    padding: 2rem;
    font-size: x-large;
    //box-shadow: 0 0 0.625rem #d1d1d1;
  }
  .done {
    padding: 2rem;
    p{
      line-height: 2;
    }
  }
  .frombox {
    width: 100%;
    /deep/ .el-form {
      // width: 34.375rem;
      margin-top: 2rem;
      .el-form-item {
        display: flex;
        padding: 5px;
        align-items: center;
        width: 50rem;
        margin-bottom: 1.125rem;
        .el-form-item__label {
          width: 6.875rem;
          box-sizing: border-box;
          text-align: right;
          padding-right: 0.75rem;
          font-size: 0.875rem;
          color: #606266;
        }
        .el-form-item__content {
          flex: 1;
          display: flex;
          align-items:center;
          .el-checkbox__inner {
            width: 1rem;
            height: 1rem;
            border: 0.0625rem solid #dcdfe6;
            border-radius: 0.125rem;
          }
          .el-checkbox__label {
            margin-left: 0.1875rem;
            font-size: 0.75rem;
          }
          .el-checkbox__inner::after {
            border: 0.125rem solid #fff;
            height: 0.5rem;
            width: 0.3rem;
            border-left: 0;
            left: 0.1875rem;
            border-top: 0;
          }
          button {
            padding: 0 1rem 0 1rem;
            margin-left: 1rem;
            height: 2rem;
            width: 50%;
          }
          input {
            height: 2rem;
            padding: 0 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
            .emcode{
              width: 20rem;
            }
          }
          textarea {
            width: 100%;
            padding: 0.625rem 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  .regbtn {
    padding: 0 2rem 0 2rem;
    margin: 1rem;
    height: 2rem;
    
    color: #fff;
    background-color: #409eff;
    border-radius: 0.3125rem;
    font-size: 0.75rem;
  }
  .regbtn:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .regbtn:active {
    background-color: #3a8ee6;
  }
}
</style>
